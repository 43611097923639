import { EnvironmentId } from 'src/app/common';

export const environment = {
  production: false,
  apiBaseUrl: 'http://hc-nurseportal-api-ncus-dev-app-win.azurewebsites.net/',
  // apiBaseUrl: 'http://192.168.0.107/NursePortalApi/',
  timeBaseUrl: 'https://hctimeuserapi-test.azurewebsites.net/api/user',
  expenseManagementUrl: 'https://hcarcherapi-test.azurewebsites.net/api/ExpenseRequest',
  onDemandBaseUrl: 'https://ondemand-test.hctravelnursing.com',
  credentiallySSOUrl: 'https://training.hctn.credentially.io/',
  marketingBaseUrl: 'https://www.hctravelnursing.com',
  nurseSentimentApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/nursesentiment/dev',
  ionicAuthConfig: {
    clientId: '4980e360-e157-4e4a-ab77-1bc8f131ad6f',
    discoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signin',
    passwordResetDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_passwordreset',
    passwordChangeDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_PasswordChange',

    signupDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_signup',
    internalLoginAuthority: 'https://login.microsoftonline.com/ea791863-b17d-43ec-90ba-148d3fa0a86f',
    webRedirectUri: 'https://ondemand-test.hctravelnursing.com/redirect',
    appRedirectUri: 'com.hctravelnursing.ondemand://redirect',
    webLogoutUrl: 'http://localhost:4200/',
    appLogoutUrl: 'com.hctravelnursing.ondemand://logout',
    defaultScopes: 'https://HealthCarouselB2cTest.onmicrosoft.com/api/write openid',
    archerApi: {
      scopes: 'openid profile https://HealthCarouselB2c.onmicrosoft.com/763c1a5f-faf1-4ff8-b540-74f5419d774d/access_as_user',
      audience: '763c1a5f-faf1-4ff8-b540-74f5419d774d'
    },
    internalScopes: 'api://b179983a-e57a-4b0b-82a2-b9535d8bdc39/access_as_user',
    timeApi: {
      scopes: 'openid profile https://HealthCarouselB2c.onmicrosoft.com/71f7a854-9266-4ecb-af0d-4fcf56849537/assignment.read',
      audience: '71f7a854-9266-4ecb-af0d-4fcf56849537'
    },
    hcinApi: {
      scopes: 'https://HealthCarouselB2cTest.onmicrosoft.com/default/access_as_user openid profile',
      audience: 'db581ab5-819a-4f4b-8156-7b2b0d1c1a31'
    },
    audience: 'HealthCarouselB2cTest'
  },
  amplitudeAPIKey: 'client-hWSnCFiMDyp5x4bdT53fZa9lcfAOdufc',
  environmentId: EnvironmentId.Dev,
  appInsightsLey: 'a810949f-e6a3-410d-bcb7-53b32b0d6477',
  sentryEnvironment: null,
  sentrySampleRate: 0.0,
  appBaseUrl: 'https://ondemand-test.hctravelnursing.com',
  releaseVersion: '9.41.0',
  ocpUsersApimSubscriptionKey: '6c1e43774d22486fbfdd45c4aadb5cbc',
  usersApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/dev/hctn/users/',
  ocpGuestApimSubscriptionKey: 'de95b6e5468d4f4395a58e3801a7736e',
  guestApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/guestservice/dev/',
  ocpCandidateJobsApimSubscriptionKey: 'a6c8eaf50b084612b44d39bc656fa32f',
  candidateJobsApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/candidate/jobs/dev/',
  internationalApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/hcin/dev/',
  internationalApimSubscriptionKey: '8a5832f4342042b78633e7695abf25d4',
};
